import {toAbsoluteUrl} from '_metronic/helpers'

export * from './attendance'
export * from './library'
export * from './request'
export * from './user'

const constants = {
  session_db_postfix_key: 'active_session_postfix',

  api_base_url: process.env.REACT_APP_API_URL ?? 'https://api.ilmversity.net/api/',
  //api_base_url: 'http://localhost:5000/api/',

  //api_platform_url: 'ilmversity.com',
  version: 'v1/',

  // notification polling
  notification_polling_delay: 1000 * 60 * 10,

  // user messages polling
  user_messages_polling_delay: 1000 * 30,

  // General Settings
  api_general_settings: 'general-settings',

  // User API's
  api_get_all_users: 'user',

  // Settings
  api_id_card_settings: 'settings/id-card',
  api_staff_id_card_settings: 'settings/staff-id-card',
  api_outline_settings: 'settings/outline',
  api_comprehansive_settings: 'settings/comprehansive',
  api_predicted_settings: 'settings/predicted',
  api_ear_services: 'settings/ear-service',
  api_admission_settings: 'settings/admission',
  api_update_enable_admission_form: 'settings/enable-admission',

  // Tenant Sessions
  api_get_tenant_sessions: 'tenant-sessions',

  // file upload
  api_get_signed_upload_url: 'signed-url',
  api_confirm_file_upload: 'confirm-upload',

  // Login APIs
  api_login: 'user/login',
  api_login_jwt: 'user/login/jwt',
  api_device_token: 'user/device-tokens',
  api_logout: 'user/logout',
  api_user_me: 'user/me',
  api_user_active_staff: 'user/active-staff',
  api_sms_services: 'user/sms-services',
  api_user_all_active_students: 'user/all-active-students',
  api_user_all_active_teachers: 'user/all-active-teachers',
  api_user_all_active_staff: 'user/all-active-staff',
  api_user_all_active_users_attendance: 'user/teacher-staff-attendance',
  api_user_all_active_students_attendance: 'user/students-attendance',
  api_user_teachers_subject_attendance: 'user/teachers-subject-attendance',
  api_user_students_subject_attendance: 'user/students-subject-attendance',
  api_inactive_users: 'user/inactivate',
  api_activate_users: 'user/activate',
  api_move_users_to_junk: 'user/move-to-junk',
  api_user_impersonate: 'user/impersonate',
  api_verify_password: 'user/verify-password',
  api_update_password: 'user/branchwise-password-change',

  // Forgot Password APIs
  api_forgot_password: 'user/forgot-password',
  api_reset_password: 'user/reset-password',

  // Send Feedback API
  api_feedback: 'user/send-feedback',

  api_get_branch_logo_signed_upload_url: 'school/branch/branch-logo-signed-upload-url',
  api_confirm_branch_logo_file_upload: 'school/branch/confirm-branch-logo-upload',
  api_get_branch_banner_signed_upload_url: 'school/branch/branch-banner-signed-upload-url',
  api_confirm_branch_banner_file_upload: 'school/branch/confirm-branch-banner-upload',
  api_check_branch_limit: 'school/branch/is-creation-allowed',

  api_get_departments: 'school/department',
  api_get_department: (id: number) => `school/department/${id}`,
  api_create_department: 'school/department/create',
  api_edit_department: (id: number) => `school/department/${id}`,
  api_delete_department: (id: number) => `school/department/${id}`,

  api_get_branches: 'school/user/branches',
  api_delete_branch: (id: number) => `school/branch/${id}`,

  // exam type
  api_get_all_exam_types: 'exam-type',

  /**
   * Admission form settings APIs START
   */
  // Admission Form Sections settings
  api_admission_sections: 'school/admission/settings/sections',
  api_admission_section: (id: number) => `school/admission/settings/sections/${id}`,
  api_admission_section_fields: (id: number) => `school/admission/settings/sections/${id}/fields`,
  api_update_admission_setting_by_id: 'school/admission/settings/update-setting-by-id',

  // Admission form Subject Groups APIs
  api_get_subject_groups: 'school/admission/settings/subject_groups',
  api_get_subject_group: (id: number) => `school/admission/settings/subject_groups/${id}`,
  api_create_subject_group: 'school/admission/settings/subject_groups/create',
  api_edit_subject_group: (id: number) => `school/admission/settings/subject_groups/${id}`,
  api_delete_subject_group: (id: number) => `school/admission/settings/subject_groups/${id}`,

  // Admission form Subjects APIs
  api_get_admission_subjects: 'school/admission/settings/admission_subjects',
  api_get_admission_subject: (id: number) => `school/admission/settings/admission_subjects/${id}`,
  api_create_admission_subject: 'school/admission/settings/admission_subjects/create',
  api_edit_admission_subject: (id: number) => `school/admission/settings/admission_subjects/${id}`,
  api_delete_admission_subject: (id: number) =>
    `school/admission/settings/admission_subjects/${id}`,

  // Admission Questions APIs
  api_get_admission_questions: 'school/admission/settings/admission_questions',
  api_get_admission_question: (id: number) => `school/admission/settings/admission_questions/${id}`,
  api_create_admission_question: 'school/admission/settings/admission_questions/create',
  api_edit_admission_question: (id: number) =>
    `school/admission/settings/admission_questions/${id}`,
  api_delete_admission_question: (id: number) =>
    `school/admission/settings/admission_questions/${id}`,

  // Admission Subject Exams APIs
  api_get_admission_subject_exams: 'school/admission/settings/subject_exam',
  api_get_admission_subject_exam: (id: number) => `school/admission/settings/subject_exam/${id}`,
  api_create_admission_subject_exam: 'school/admission/settings/subject_exam/create',
  api_edit_admission_subject_exam: (id: number) => `school/admission/settings/subject_exam/${id}`,
  api_delete_admission_subject_exam: (id: number) => `school/admission/settings/subject_exam/${id}`,

  // Admission Subjects Grades APIs
  api_get_subjects_grades: 'school/admission/settings/subjects_grades',
  api_get_subjects_grade: (id: number) => `school/admission/settings/subjects_grades/${id}`,
  api_create_subjects_grade: 'school/admission/settings/subjects_grades/create',
  api_edit_subjects_grade: (id: number) => `school/admission/settings/subjects_grades/${id}`,
  api_delete_subjects_grade: (id: number) => `school/admission/settings/subjects_grades/${id}`,

  // Previous Subjects APIs
  api_get_admission_previous_subjects: 'school/admission/settings/previous_subjects',
  api_get_previous_subject: (id: number) => `school/admission/settings/previous_subjects/${id}`,
  api_create_previous_subject: 'school/admission/settings/previous_subjects/create',
  api_edit_previous_subject: (id: number) => `school/admission/settings/previous_subjects/${id}`,
  api_delete_previous_subject: (id: number) => `school/admission/settings/previous_subjects/${id}`,

  // Subject Combinations APIs
  api_get_subject_combinations: 'school/admission/settings/subject_combinations',
  api_get_subject_combination: (id: number) =>
    `school/admission/settings/subject_combinations/${id}`,
  api_create_subject_combination: 'school/admission/settings/subject_combinations/create',
  api_edit_subject_combination: (id: number) =>
    `school/admission/settings/subject_combinations/${id}`,
  api_delete_subject_combination: (id: number) =>
    `school/admission/settings/subject_combinations/${id}`,

  // Subject Components APIs
  api_get_subject_components: 'school/admission/settings/subject_components',
  api_get_subject_component: (id: number) => `school/admission/settings/subject_components/${id}`,
  api_create_subject_component: 'school/admission/settings/subject_components/create',
  api_edit_subject_component: (id: number) => `school/admission/settings/subject_components/${id}`,
  api_delete_subject_component: (id: number) =>
    `school/admission/settings/subject_components/${id}`,

  // Previous Classes APIs
  api_get_admission_previous_classes: 'school/admission/settings/previous_classes',
  api_get_previous_class: (id: number) => `school/admission/settings/previous_classes/${id}`,
  api_create_previous_class: 'school/admission/settings/previous_classes/create',
  api_edit_previous_class: (id: number) => `school/admission/settings/previous_classes/${id}`,
  api_delete_previous_class: (id: number) => `school/admission/settings/previous_classes/${id}`,

  // Previous Schools APIs
  api_get_previous_schools: 'school/admission/settings/previous_schools',
  api_get_previous_school: (id: number) => `school/admission/settings/previous_schools/${id}`,
  api_create_previous_school: 'school/admission/settings/previous_schools/create',
  api_edit_previous_school: (id: number) => `school/admission/settings/previous_schools/${id}`,
  api_delete_previous_school: (id: number) => `school/admission/settings/previous_schools/${id}`,

  // Admission form Stages APIs
  api_get_admission_stages: 'school/admission/settings/admission_stages',
  api_get_admission_stage: (id: number) => `school/admission/settings/admission_stages/${id}`,
  api_edit_admission_stage: (id: number) => `school/admission/settings/admission_stages/${id}`,

  /**
   * Admission form settings APIs END
   */

  // Homework APIs
  api_get_homeworks: 'homework',
  api_get_homework_detail: 'homework/',
  api_homework_get_messages: '/messages/get',
  api_homework_send_messages: '/messages/send',
  api_get_signed_upload_url_homework: 'homework/signed-upload-url',
  api_confirm_file_upload_homework: 'homework/confirm-file-upload',
  api_delete_homework_file: 'homework/delete-student-file',
  api_submit_homework: 'homework/submit',
  api_get_all_homework: 'school/homework',
  api_get_homework: (id: string) => `school/homework/${id}`,
  api_add_homework: 'school/homework/create',
  api_add_homework_resource: 'school/homework/resources/create',
  api_delete_homework: (id: string) => `school/homework/${id}`,
  api_get_homework_resources: 'school/homework/resources',
  api_delete_homework_resource: (id: string) => `school/homework/resources/${id}`,
  api_update_homework: (id: string) => `school/homework/${id}`,
  api_delete_many_homeworks: 'school/homework/delete-many',
  api_add_many_homeworks: 'school/homework/multi-create',
  api_get_homework_reports: 'school/homework/reports',
  api_get_homework_results: (id: string) => `school/homework/${id}/results`,
  api_update_homework_results: 'school/homework/update-result',
  api_view_homework_submission: (id: string) => `school/homework/submission-files/${id}`,
  api_add_homework_feedback: 'school/homework/feedback-files/create',
  api_get_homework_feedback: (id: string) => `school/homework/feedback-files/${id}`,
  api_delete_homework_feedback: (id: string) => `school/homework/feedback-files/${id}`,
  api_assign_homework_marks_to_all: 'school/homework/bulk-assign-marks',
  api_assign_top_homeworks: 'school/homework/assign-top-homeworks',

  // Inventory Item Category APIs
  api_add_item: 'school/item-categories/create',
  api_update_item: (id: string) => `school/item-categories/${id}`,
  api_get_all_items: 'school/item-categories',
  api_delete_item_by_id: (id: string) => `school/item-categories/${id}`,
  api_get_item_by_id: (id: string) => `school/item-categories/${id}`,

  // Inventory Store Items  APIs
  api_add_store_item: 'school/store-items/create',
  api_add_store_item_history: `school/store-items/history`,
  api_update_store_item: (id: string) => `school/store-items/${id}`,
  api_get_all_store_items: 'school/store-items',
  api_get_all_store_items_tags: 'school/store-items/tags',
  api_get_stock_report: 'school/store-items/stock-report',
  api_get_purchase_order_detail_report: 'school/store-items/purchase-order-report',
  api_get_issue_sold_stock_report: 'school/store-items/issued-sold-stock-report',
  api_get_all_store_items_permitted_tags: 'school/store-items/permitted-tags',
  api_delete_store_item_by_id: (id: string) => `school/store-items/${id}`,
  api_get_store_item_by_id: (id: string) => `school/store-items/${id}`,
  api_delete_store_item_in_bulk: 'school/store-items/delete-many',

  // Inventory Store Items Packages  APIs
  api_add_store_item_package: 'school/packages/create',
  api_update_store_item_package: (id: string) => `school/packages/${id}`,
  api_get_all_store_items_package: 'school/packages',
  api_delete_store_item_package_by_id: (id: string) => `school/packages/${id}`,
  api_get_store_item_package_by_id: (id: string) => `school/packages/${id}`,
  api_delete_store_item_package_in_bulk: 'school/packages/delete-many',

  // purchase order urls
  api_get_purchase_orders: 'school/purchase-orders',
  api_get_purchase_order: (id: number) => `school/purchase-orders/${id}`,
  api_add_purchase_order: 'school/purchase-orders/create',
  api_update_purchase_order: (id: number) => `school/purchase-orders/${id}`,
  api_delete_purcahse_order_by_id: (id: number) => `school/purchase-orders/${id}`,
  api_delete_purcahse_order_in_bulk: 'school/purchase-orders/delete-many',
  api_get_purchase_order_report: (id: number) =>
    `school/purchase-orders/${id}/purchase-order-report`,

  // goods receipt urls
  api_get_goods_receipts: 'school/goods-receipts',
  api_get_goods_receipt: (id: number) => `school/goods-receipts/${id}`,
  api_update_goods_receipt: (id: number) => `school/goods-receipts/${id}`,
  api_add_goods_receipt: 'school/goods-receipts/create',
  api_delete_goods_receipt_by_id: (id: number) => `school/goods-receipts/${id}`,
  api_delete_goods_receipt_in_bulk: 'school/goods-receipts/delete-many',
  api_get_goods_receipt_report_by_id: (id: string) =>
    `school/goods-receipts/${id}/goods-receipt-report`,

  // invoices urls
  api_get_invoices: 'school/invoices',
  api_get_invoice: (id: number) => `school/invoices/${id}`,
  api_add_invoice: 'school/invoices/create',
  api_update_invoice: (id: string) => `school/invoices/${id}`,
  api_delete_invoice_by_id: (id: number) => `school/invoices/${id}`,
  api_delete_invoices_in_bulk: 'school/invoices/delete-many',
  api_get_invoice_report_by_id: (id: string) => `school/invoices/${id}/invoice-report`,

  //inventorySettings urls
  api_get_inventory_settings: 'school/inventory-settings',
  api_update_inventory_settings: 'school/inventory-settings/update',

  // Setting APIs
  api_setting: 'user/user-settings', //set and get

  api_get_profile_picture_upload_url: 'user/profile-picture/signed-url',
  api_get_user_profile_picture_upload_url: (id: string) => `user/${id}/profile-picture/signed-url`,
  api_confirm_profile_picture_upload: 'user/profile-picture/confirm-upload',
  api_confirm_profile_picture_upload_any_user: (id: string) =>
    `user/${id}/profile-picture/confirm-upload`,

  // General APIs
  api_get_user_subjects: 'user/subjects',
  api_post_student_subjects: 'subjects/students',

  // Profile APIs
  api_get_profile: 'user/profile-details',
  api_get_child_profile: 'user/child-profile',

  // Lecture APIs
  api_get_lectures: 'school/lecture/getAll',
  api_get_lecture_detail: (id: string) => `school/lecture/${id}`,
  api_delete_lecture: (id: string) => `school/lecture/${id}`,
  api_add_lecture: 'school/lecture/create',
  api_update_lecture: (id: string) => `school/lecture/${id}`,
  api_get_online_user: (id: string) => `school/lecture/${id}/onlineUser`,
  api_add_comment: (id: string) => `school/lecture/${id}/comment`,
  api_update_comment: (id: string) => `school/lecture/comment/${id}`,
  api_delete_comment: (id: string) => `school/lecture/deleteComment/${id}`,
  api_get_all_lecture_comments: (id: string) => `school/lecture/comments/${id}`,

  //ScheduleAPis
  api_get_yearlySchedule: 'schedule/yearly/calendar',
  api_get_dailyScheduleActivities: 'schedule/daily/calendar/activitieswise',
  api_get_dailyScheduleClasswise: 'schedule/daily/calendar/classwise',
  api_get_dailyScheduleTeacherwise: 'schedule/daily/calendar/teacherwise',
  api_get_yearlyList: 'schedule/yearly',
  api_add_ySchedule: 'schedule/yearly/create',
  api_add_dSchedule: 'schedule/daily/create',
  api_add_classroom: 'schedule/daily/class/create',
  api_get_dailyList: 'schedule/daily',
  api_get_classList: 'schedule/daily/class',
  api_add_extraclass: 'schedule/daily/extra-class',
  api_delete_Yschedule: (id: string) => `schedule/yearly/delete/${id}`,
  api_delete_Dschedule: (id: string) => `schedule/daily/delete/${id}`,
  api_delete_many_Dschedule: `schedule/daily/delete-many`,
  api_delete_DscheduleClasses: (id: string) => `schedule/daily/class/delete/${id}`,
  api_get_ClassroomById: (id: string) => `schedule/daily/class/${id}`,
  api_get_yScheduleById: (id: string) => `schedule/yearly/${id}`,
  api_get_dScheduleById: (id: string) => `schedule/daily/${id}`,
  api_update_ySchedule: (id: string) => `schedule/yearly/update/${id}`,
  api_update_dSchedule: (id: string) => `schedule/daily/update/${id}`,
  api_update_classRoom: (id: string) => `schedule/daily/class/update/${id}`,

  //Notifications APIs
  api_get_notifications: 'user/notifications',
  api_get_new_notifications_count: 'user/notifications/count',
  api_read_notifications: 'user/notifications/read',
  api_read_all_notifications: 'user/notifications/read-all',

  //News APIs
  api_add_news: 'news/create',
  api_update_news: (id: string) => `news/${id}`,
  api_send_news_notifications: (id: string) => `news/${id}/notifications`,
  api_send_news_sms: (id: string) => `news/${id}/sms`,
  api_news_approval_status: (id: string) => `news/${id}/approval-status`,
  api_news_approval_info: (id: string) => `news/${id}/approval-info`,
  api_get_all_news: 'news',
  api_delete_news_by_id: (id: string) => `news/${id}`,
  api_delete_news_cover_by_id: (id: string) => `news/${id}/destroy-cover-image`,
  api_delete_news_attachment_by_id: (id: string) => `news/${id}/destroy-attachment`,
  api_get_news_by_id: (id: string) => `news/${id}`,
  api_delete_news_in_bulk: 'news/delete-many',
  api_generate_news_report: 'news/generate-reports',

  //Username, Password for Fee APIs
  username_fee_api: "&3,dPQ~7TQ(jf}';",
  password_fee_api: 'N_j6!:d*LxUzrVP=',
  //Fee APIs
  api_get_fee_history: 'school/finance/feeApi/getfeehistory',
  api_download_voucher: 'school/finance/feeApi/downloadvoucher',
  api_student_profile_fee_particulars: 'fee/student-profile-fee-particulars',
  api_get_class_fee_package_details: (id: string) => `fee/class-fee-package-details/${id}`,

  // user gender
  user_gender_male: 0,
  user_gender_female: 1,

  //userManager
  //Student apis
  api_update_student_generalRById: (id: string) => `student/remarks/general/${id}`,
  api_update_student_subjectRyId: (id: string) => `student/remarks/subject/${id}`,
  api_update_student_ById: (id: string) => `student/${id}/update`,
  api_get_student_generalRById: (id: string) => `student/${id}/remarks/general`,
  api_add_studentSportRemarksById: (id: string) => `student/${id}/sports/remarks`,
  api_get_student_subjectRyId: (id: string) => `student/${id}/remarks/subject`,
  api_add_student_generalRById: (id: string) => `student/${id}/remarks/general/create`,
  api_add_student_subjectRyId: (id: string) => `student/${id}/remarks/subject/create`,
  api_get_students: 'student',
  api_get_student_number: 'student/latest-number',
  api_get_student_file_types: 'student/file-types',
  api_add_upload_file: (id: string) => `student/${id}/files/upload`,
  api_add_student_sport: `student/add/sport`,
  api_add_student_sport_by_id: `student/sports/assign`,
  api_get_new_admission_student: 'student/new-admission',
  api_get_class_history: (id: number) => `student/${id}/class-history`,
  api_create_class_history: (id: number) => `student/${id}/create-class-history`,
  api_get_academic_record: (id: number) => `student/${id}/academic-record`,
  api_get_counsellor_mentor: `student/counsellor-mentor`,
  api_get_donars: `student/donars`,

  api_generate_student_list_report: 'student/generate-students-list',
  api_generate_student_login_report: 'student/generate-students-login-report',
  api_sms_login_details: 'student/sms-login-details',
  api_email_login_details: 'student/email-login-details',
  api_add_student_siblings: 'student/add-siblings',
  api_get_student_sports: 'student/sports',
  api_create_student: 'student/create',
  api_get_student: (id: string) => `student/${id}`,
  api_get_student_parent: (id: string) => `student/${id}/parents`,
  api_get_student_academics: (id: string) => `student/${id}/academics`,
  api_get_student_academics_previous_exams: (id: string) =>
    `student/${id}/academics/exams/previous`,
  api_get_student_academics_exams: (id: string) => `student/${id}/academics/exams`,
  api_get_student_academics_tests: (id: string) => `student/${id}/academics/tests`,
  api_get_student_academic_homeworks: (id: string) => `student/${id}/academics/homework`,
  api_get_student_fees: (id: string) => `student/${id}/fees`,
  api_get_student_sports_remarks: (id: string) => `student/${id}/sport-remarks`,
  api_update_student_sports_remarks: (id: string) => `student/${id}/sport-remarks`,
  api_get_student_user_id: (id: string) => `student/${id}/user-id`,
  api_get_student_siblings: (id: string) => `student/${id}/siblings`,
  api_get_student_homework: (id: string) => `student/${id}/homework/assigned`,
  api_get_student_subjects: (id: string) => `student/${id}/subjects`,
  api_get_student_tests: (id: string) => `student/${id}/tests/assigned`,
  api_get_student_exams: (id: string) => `student/${id}/exams/assigned`,
  api_get_student_homework_unassigned: (id: string) => `student/${id}/homework/unassigned`,
  api_get_student_tests_unassigned: (id: string) => `student/${id}/tests/unassigned`,
  api_get_student_exams_unassigned: (id: string) => `student/${id}/exams/unassigned`,
  api_add_student_homework_assign: (id: string) => `student/${id}/homework/assign`,
  api_add_student_tests_assign: (id: string) => `student/${id}/tests/assign`,
  api_add_student_exams_assign: (id: string) => `student/${id}/exams/assign`,
  api_get_student_descriptions: (id: string) => `student/${id}/descriptions`,
  api_delete_student_records: (id: string) => `student/${id}/records`,
  api_delete_student_general_remarks: (id: string) => `student/${id}/remarks/destroy/general`,
  api_delete_student_subject_remarks: (id: string) => `student/${id}/remarks/destroy/subject`,
  api_delete_student_homework: (id: string) => `student/homework/destroy/${id}`,
  api_delete_student_test: (id: string) => `student/tests/destroy/${id}`,
  api_delete_student_exam: (id: string) => `student/exams/destroy/${id}`,
  api_get_uploaded_student_file: (id: string) => `student/${id}/files/uploaded`,
  api_delete_uploaded_file: `student/files/uploaded`,

  // parent
  api_add_parent: 'parent/create',
  api_sms_parent_login_details: 'parent/sms-login-details',
  api_email_parent_login_details: 'parent/email-login-details',
  api_generate_parent_login_report: 'parent/generate-parents-login-report',
  api_generate_parent_sibling_report_pdf: 'parent/sibling-report-pdf',
  api_generate_parent_sibling_report_excel: 'parent/sibling-report-excel',
  api_add_parent_emergency_contacts: 'parent/add-emergency-contacts',
  api_update_parent_ById: (id: string) => `parent/update/${id}`,
  api_get_parents: 'parent',
  api_get_parent: (id: string) => `parent/${id}`,
  api_get_parent_children: (id: string) => `parent/${id}/children`,
  api_get_parent_emergency_contacts: (id: string) => `parent/${id}/emergency-contacts`,

  // teacher routes
  api_update_teacher_by_id: (id: string) => `teacher/update/${id}`,
  api_create_teacher: 'teacher/create',
  api_get_teacher_list: 'teacher',
  api_in_activate_teacher_by_id: (id: string) => `teacher/${id}/inactivate`,
  api_get_teacher_info_by_id: (id: string) => `teacher/${id}`,
  api_get_teacher_classes_by_id: (id: string) => `teacher/${id}/classes`,
  api_get_teacher_subjects_by_id: (id: string) => `teacher/${id}/subjects`,
  api_get_teacher_qualification_by_id: (id: string) => `teacher/${id}/qualifications`,
  api_get_teacher_experience_by_id: (id: string) => `teacher/${id}/experiences`,
  api_get_teacher_children_by_id: (id: string) => `teacher/${id}/children`,
  api_del_teacher_destroy_general_remarks: (id: string) => `teacher/${id}/remarks/destroy/general`,
  api_get_teacher_general_remarks_by_id: (id: string) => `teacher/${id}/remarks/general`,
  api_add_teacher_general_remarks_by_id: (id: string) => `teacher/${id}/remarks/general/create`,
  api_update_teacher_general_remarks_ById: (id: string) => `teacher/remarks/general/${id}`,
  api_get_teacher_uploaded_files: (id: string) => `teacher/${id}/files/uploaded`,
  api_get_teacher_file_types: 'teacher/file-types',
  api_add_teacher_upload_file: (id: string) => `teacher/${id}/files/upload`,
  api_sms_teacher_login_details: 'teacher/sms-login-details',
  api_email_teacher_login_details: 'teacher/email-login-details',
  api_generate_teacher_login_report: 'teacher/generate-teachers-login-report',
  api_generate_teacher_list_report_pdf: 'teacher/generate-teacher-list-report-pdf',
  api_generate_teacher_list_report_excel: 'teacher/generate-teacher-list-report-excel',
  api_confirm_signature_picture_upload: (id: string) =>
    `teacher/${id}/signature-picture/confirm-upload `,

  // staff routes
  api_get_all_user_staff: 'staff',
  api_create_staff: 'staff/create',
  api_get_staff: (id: number) => `staff/${id}`,
  api_update_staff: (id: number) => `staff/${id}`,
  api_get_staff_qualifications: (id: number) => `staff/${id}/qualifications`,
  api_get_staff_experiences: (id: number) => `staff/${id}/experiences`,
  api_get_staff_children: (id: number) => `staff/${id}/children`,
  api_confirm_staff_signature_upload: (id: number) => `staff/${id}/signature/confirm-upload `,
  api_upload_user_docs: `staff/upload-docs`,
  api_get_uploaded_docs: `staff/uploaded-docs`,
  api_sms_staff_login_details: 'staff/sms-login-details',
  api_email_staff_login_details: 'staff/email-login-details',
  api_generate_staff_list_report_pdf: 'staff/generate-staff-list-report-pdf',
  api_generate_staff_list_report_excel: 'staff/generate-staff-list-report-excel',
  api_generate_staff_login_report: 'staff/generate-staff-login-report',

  // school routes
  api_edit_school_user_profile: 'school/user/profile',

  api_change_school_user_password: 'school/user/change-password',

  api_get_user_qualifications: 'school/user/qualifications',
  api_add_user_qualifications: 'school/user/qualifications',

  api_get_user_experiences: 'school/user/experiences',
  api_add_user_experiences: 'school/user/experiences',

  api_get_user_children: 'school/user/children',
  api_add_user_children: 'school/user/children',

  api_get_user_bank_info: 'school/user/bank-info',
  api_update_user_bank_info: 'school/user/bank-info',

  // vendor urls
  api_get_vendors: 'school/vendor',
  api_get_vendor: (id: string) => `school/vendor/${id}`,
  api_add_vendor: 'school/vendor/create',
  api_update_vendors: (id: string) => `school/vendor/${id}`,
  api_delete_vendor: (id: string) => `school/vendor/${id}`,
  api_register_vendor: (id: string) => `school/vendor/${id}/register`,
  api_get_vendor_signed_upload_url: 'school/vendor/signed-upload-url',
  api_delete_vendor_old_cnic_files: (id: string) => `school/vendor/delete-cnic/${id}`,
  api_confirm_vendor_file_upload: 'school/vendor/confirm-file-upload',

  // vendor settings urls
  api_get_vendor_settings: 'school/vendor-settings',
  api_update_vendor_settings: 'school/vendor-settings',

  api_get_user_messages: 'messages/user',

  api_get_account_types: 'school/account-types',
  api_get_logs: 'school/dbLog',
  api_get_sms_logs: 'school/smsLog',
  api_get_account_type: (id: string) => `school/account-types/${id}`,
  api_get_whatsapp_logs: 'school/whatsappLog',

  api_get_notification_logs: 'school/notificationLog',
  api_get_biometric_csv_logs: 'school/biometricCsvLog',
  api_get_biometric_device_logs: 'school/biometricDeviceLog',
  api_get_biometric_logs: 'school/biometricLog',

  // Threads
  api_get_threads: 'threads',
  api_create_threads: 'threads/create',
  api_get_thread_messages: (id: string) => `threads/${id}/messages`,
  api_read_thread_messages: (id: string) => `threads/${id}/read-messages`,

  api_send_message: `messages/send`,

  // Request Module urls
  // request urls
  api_get_requests: 'school/requests',
  api_get_request: (id: number) => `school/requests/${id}`,
  api_add_request: 'school/requests/create',
  api_update_request: (id: number) => `school/requests/${id}`,
  api_delete_request: (id: number) => `school/requests/${id}`,
  api_get_smslog: (id: number) => `school/smsLog/${id}`,
  api_get_notificationlog: (id: number) => `school/notificationLog/${id}`,

  api_get_request_signed_upload_url: 'school/requests/signed-upload-url',
  api_confirm_request_file_upload: 'school/requests/confirm-file-upload',
  api_delete_request_attachment: (requestId: number, attachmentId: number) =>
    `school/requests/${requestId}/attachments/${attachmentId}`,

  // request settings urls
  api_get_request_settings: 'school/request-settings',
  api_update_request_settings: 'school/request-settings',

  // permission urls
  api_get_permissions: 'school/permissions',
  api_get_permission: (id: number) => `school/permissions/${id}`,

  // role module permissions urls
  api_add_role_module_permission: 'school/role-module-permission/create',
  api_get_role_module_permission_by_level: (level_id: number) =>
    `school/role-module-permission/by-level/${level_id}`,
  api_delete_role_module_permissions_by_level: (level_id: number) =>
    `school/role-module-permission/${level_id}`,

  // transaction urls
  api_get_transactions: 'school/transactions',
  api_get_transaction: (id: number) => `school/transactions/${id}`,

  // transaction urls
  api_get_all_staff: 'school/user/staff',
  api_get_one_staff: (id: number) => `school/user/staff/${id}`,

  // Certificate Module urls
  // certificate urls
  api_get_certificates: 'school/letters',
  api_get_certificate: (id: number) => `school/letters/${id}`,
  api_add_certificate: 'school/letters/create',
  api_update_certificate: (id: number) => `school/letters/${id}`,
  api_delete_certificate: (id: number) => `school/letters/${id}`,
  api_generate_certificate: 'school/letters/generate-certificate',
  api_get_file_types: 'school/letters/file-types',

  // label urls
  api_get_labels: 'school/labels',
  api_search_labels: 'school/labels/search',
  api_get_label: (id: number) => `school/labels/${id}`,
  api_add_labels: 'school/labels/create',
  api_update_label: (id: number) => `school/labels/${id}`,
  api_delete_label: (id: number) => `school/labels/${id}`,

  // class urls
  api_get_classes: 'school/classes',
  api_get_users_active_classes: 'school/get-users-active-classes',
  api_get_class: (id: number) => `school/classes/${id}`,
  api_add_class: 'school/classes/create',
  api_delete_class: (id: number) => `school/classes/${id}`,
  api_delete_many_classes: 'school/classes/delete-many',
  api_update_class: (id: number) => `school/classes/${id}`,
  api_get_class_resources: `school/classes/resources`,
  api_add_class_resource: 'school/classes/resources/create',
  api_get_class_subjects: (id: number) => `school/classes/${id}/subjects`,
  api_get_class_students: (id: number) => `school/classes/${id}/students`,
  api_delete_class_resource: (id: number) => `school/classes/resources/${id}`,
  api_get_class_sequence: 'school/classes/class-sequence',
  api_update_class_sequence: 'school/classes/sequence',
  api_generate_class_strength_list: 'school/classes/strength',
  api_promote_students: 'school/classes/promote-students',
  api_get_previous_classes: 'school/classes/previous-classes',
  api_assign_teachers: 'school/classes/assign-teachers',
  api_generate_class_student_subject_report: 'school/classes/student-subjects',

  // subject urls
  api_get_subjects: 'subjects',
  api_get_subject: (id: number) => `subjects/${id}`,
  api_add_subject: 'subjects/create',
  api_update_subject: (id: number) => `subjects/${id}`,
  api_update_subject_sequence: 'subjects/sequence',
  api_get_subject_sequence: 'subjects/sequence',
  api_generate_student_list: 'subjects/student-list-report',
  api_generate_overall_subject_report: 'subjects/overall-report',
  api_set_subject_weightage: (id: number) => `subjects/${id}/set-weightage`,
  api_generate_student_subject_report: 'subjects/student-subject-report',
  api_lock_selected_outline: 'subjects/lock-outline',
  api_unlock_selected_outline: 'subjects/unlock-outline',
  api_get_previous_subjects: 'subjects/previous-subjects',
  api_get_subject_exam: 'subjects/subject-exams',
  api_assign_subject_teachers: 'subjects/assign-teachers',
  api_get_subjects_subsections: 'subjects/subsection',
  api_add_subject_subsection: 'subjects/subsection/create',
  api_update_subject_subsection: (id: number) => `subjects/subsection/${id}`,
  api_get_subject_subsection: (id: number) => `subjects/subsection/${id}`,
  api_delete_subject_subsection: (id: number) => `subjects/subsection/${id}`,
  api_delete_many_subject_subsections: 'subjects/subsection/delete-many',
  api_get_exclude_subsections: (id: number) => `subjects/${id}/excluded-subsections`,
  api_update_exclude_subsection: 'subjects/save-excluded-subsections',
  api_copy_subsection: 'subjects/copy-subsection',
  api_get_syllabus_subjects: 'subjects/syllabus-subjects',
  api_reset_subject_sequence: 'subjects/reset-sequence',

  // activity urls
  api_get_activities: 'activities',

  // level urls
  api_get_levels: 'school/levels',
  api_get_level: (id: number) => `school/levels/${id}`,
  api_view_level: (id: number) => `school/levels/view/${id}`,
  api_update_level: (id: number) => `school/levels/${id}`,
  api_delete_level: (id: number) => `school/levels/${id}`,
  api_get_active_branches: 'school/branch/active',
  api_get_all_branches: 'school/branch',
  api_add_branch: 'school/branch/add',
  api_view_branch: (id: number) => `school/branch/view/${id}`,
  api_update_branch: (id: number) => `school/branch/edit/${id}`,
  api_delete_branch_file: (id: number) => `school/branch/delete-logo-file/${id}`,
  api_delete_branch_banner_file: (id: number) => `school/branch/delete-banner-file/${id}`,

  // staff role urls
  api_get_staff_roles: 'school/staff-roles',
  api_create_staff_role: 'school/staff-roles/create',
  api_update_staff_role: (id: number) => `school/staff-roles/${id}/update`,
  api_get_staff_role: (id: number) => `school/staff-roles/${id}`,
  api_delete_staff_role: (id: number) => `school/staff-roles/${id}`,
  api_assign_permissions: (id: number) => `school/staff-roles/${id}/assign-permissions`,
  api_get_assign_permissions: (id: number) => `school/staff-roles/${id}/assign-permissions`,
  api_assign_levels: (id: number) => `school/staff-roles/${id}/assign-levels`,
  api_get_assign_levels: (id: number) => `school/staff-roles/${id}/assign-levels`,
  api_assign_departments: (id: number) => `school/staff-roles/${id}/assign-departments`,
  api_get_assign_departments: (id: number) => `school/staff-roles/${id}/assign-departments`,

  // Digital Request Module urls
  api_get_digital_requests: 'school/digital-requests',
  api_get_digital_request: (id: number) => `school/digital-requests/${id}`,
  api_update_digital_request: (id: number) => `school/digital-requests/${id}`,
  api_delete_digital_request: (id: number) => `school/digital-requests/${id}`,
  api_export_digital_requests_excel: 'school/digital-requests/excel',

  api_add_level: 'school/levels/create',
  api_edit_level: (id: string) => `school/levels/edit/${id}`,
  // Resource Module urls
  api_get_resources: 'school/resources',
  api_get_resource: (id: number) => `school/resources/${id}`,
  api_add_resource: 'school/resources/create',
  api_update_resource: (id: number) => `school/resources/${id}`,
  api_delete_resource: (id: number) => `school/resources/${id}`,
  api_get_resource_signed_upload_url: 'school/resources/signed-upload-url',
  api_confirm_resource_file_upload: 'school/resources/confirm-file-upload',
  api_delete_resource_file: (resourceId: number, fileId: number) =>
    `school/resources/${resourceId}/files/${fileId}`,

  // Module name urls
  api_get_module_names: 'school/module-names',

  // Tags Module urls
  api_get_tags: 'school/tags',
  api_get_tag: (id: number) => `school/tags/${id}`,
  api_add_tag: 'school/tags/create',
  api_update_tag: (id: number) => `school/tags/${id}`,
  api_delete_tag: (id: number) => `school/tags/${id}`,

  // Tags Category urls
  api_get_tags_categories: 'school/tag-categories',
  api_get_tags_category: (id: number) => `school/tag-categories/${id}`,
  api_add_tags_category: 'school/tag-categories/create',
  api_update_tags_category: (id: number) => `school/tag-categories/${id}`,
  api_delete_tags_category: (id: number) => `school/tag-categories/${id}`,

  api_load_custom_names_data: 'school/customNames',
  api_update_custom_names_data: 'school/customNames/update-custom-names',
  api_load_custom_names_mappings: 'school/customNames/get-mappings',
  api_reports_test: 'reports/test',

  api_get_student_dropout_pdf: 'school/students/dropout-pdf',
  api_get_month_wise_student_dropout_pdf: 'school/students/month-wise-dropout-pdf',

  // Attendance urls
  api_biometric_attendance: `attendance`,
  // Bulk Upload url
  api_bulk_upload: 'bulk-upload',
  api_track_progress_bar: 'bulk-upload/progress',
  api_logs_record: (id: number) => `bulk-upload/logs/${id}`,
  api_attendance_mark_attendance: `attendance/mark`,
  api_attendance_unmark_attendance: `attendance/unmark`,
  api_attendance_checkout_details: `attendance/checkout-details`,
  api_attendance_update_attendance: `attendance/update`,
  api_attendance_setting_settings_by_branch: `attendance-setting/level-id`,
  api_get_attendance_setting: `attendance-setting/`,
  api_update_attendance_setting: `attendance-setting/update`,
  api_attendance_add_remarks: `attendance/remarks`,
  api_attendance_generate_individual_attendance_report: `attendance/generate-teacher-staff-individual-attendance-report`,
  api_attendance_generate_combine_attendance_report: `attendance/generate-teacher-staff-combine-attendance-report`,
  api_attendance_generate_individual_monthly_attendance_report: `attendance/generate-monthwise-individual-attendance-report-excel`,
  api_attendance_generate_combine_monthly_attendance_report: `attendance/generate-monthwise-combine-attendance-report-excel`,
  api_attendance_generate_teacher_subject_individual_attendance_report: `attendance/generate-teacher-subject-individual-report`,
  api_attendance_generate_teacher_subject_combine_attendance_report: `attendance/generate-teacher-subject-combine-report`,
  api_attendance_generate_student_subject_combine_attendance_report: `attendance/generate-student-subject-combine-report`,
  api_attendance_generate_student_subject_monthly_attendance_report: `attendance/generate-student-subject-monthly-report`,
  api_attendance_generate_student_subject_individual_attendance_report: `attendance/generate-student-subject-individual-report`,
  api_get_attendance_remarks: `attendance-remarks/`,
  api_add_attendance_remarks: `attendance-remarks/add`,
  api_attendance_remarks_get_by_type: `attendance-remarks/get-by-type`,
  api_delete_attendance_remarks: (id: number) => `attendance-remarks/${id}`,

  api_get_user_custom_attendance_schedule: `user-custom-attendance-schedule/list`,
  api_get_one_user_custom_attendance_schedule: (id: number) =>
    `user-custom-attendance-schedule/${id}`,
  api_add_user_custom_attendance_schedule: `user-custom-attendance-schedule/add`,
  api_update_user_custom_attendance_schedule: (id: number) =>
    `user-custom-attendance-schedule/update/${id}`,
  api_delete_user_custom_attendance_schedule: (id: number) =>
    `user-custom-attendance-schedule/delete/${id}`,
  api_delete_all_user_custom_attendance_schedule: `user-custom-attendance-schedule/delete-all`,
  api_get_StdMonthAttendanceById: 'attendance/get-students-month-wise-attendance',
  api_get_TodayAttendanceById: 'attendance/today-attendance',

  api_get_admission_fee_voucher_dates: 'school/admission-fee-voucher-dates',
  api_get_admission_fee_voucher_date: (id: number) => `school/admission-fee-voucher-dates/${id}`,
  api_add_admission_fee_voucher_date: 'school/admission-fee-voucher-dates',
  api_update_admission_fee_voucher_date: (id: number) => `school/admission-fee-voucher-dates/${id}`,
  api_delete_admission_fee_voucher_date: (id: number) => `school/admission-fee-voucher-dates/${id}`,
  api_delete_many_admission_fee_voucher_dates: 'school/admission-fee-voucher-dates/delete-many',
  api_get_std_month_attendance_by_id: 'attendance/get-students-month-wise-attendance',
  api_get_today_attendance_by_id: 'attendance/get-today-attendance',

  api_add_grade: 'school/grades/create',
  api_get_grades: 'school/grades',
  api_get_grade: (id: number) => `school/grades/${id}`,
  api_delete_grade: (id: number) => `school/grades/${id}`,
  api_update_grade: (id: number) => `school/grades/${id}`,
  api_delete_many_grades: 'school/grades/delete-many',
  api_get_subject_grades: `school/grades/subject-grades`,

  api_add_section: 'school/sections/create',
  api_get_sections: 'school/sections',
  api_get_section: (id: number) => `school/sections/${id}`,
  api_delete_section: (id: number) => `school/sections/${id}`,
  api_update_section: (id: number) => `school/sections/${id}`,
  api_delete_many_sections: 'school/sections/delete-many',

  //exam urls
  api_get_exam_labels: 'exam/labels',
  api_get_exam_performance_report: 'exam/performance-report',

  //Accounts
  api_add_account_type: 'school/account-types/create',
  api_delete_account_type: (id: number) => `school/account-types/${id}`,
  api_update_account_type: (id: number) => `school/account-types/${id}`,
  api_delete_many_account_type: 'school/account-types/delete-many',

  //term urls
  api_get_terms: 'terms',
}
export const base_url = constants.api_base_url + constants.version

export const CERTIFICATE_TYPES = {
  STUDENT: 0,
  HR: 1,
  CCA_CERTIFICATE: 2,
}

export const MODULES = [
  {name: 'Lectures', value: 'lecture'},
  {name: 'Homework', value: 'homework'},
  {name: 'News', value: 'news'},
  //{ name: 'Blog'},
  {name: 'Tests', value: 'test'},
  {name: 'Exams', value: 'exam'},
  //{name: 'Reports'},
  //{name: 'Attendance'},
  //{name: 'Activities'},
  {name: 'Events', value: 'event'},
  {name: 'Fee', value: 'fee'},
]

export const SECRET_KEY = {
  android: 'ePW7KwPpBVPH6QVmbFS',
  ios: '53aNQRfhMVIoLSfiM5Q',
  web: 'rr0y3T5WjEKG3OrU2NN',
}

export const RECAPTCHA = {
  siteKey: '6LcYbqoeAAAAAGFyKacGyAZbTtPnrritbVkbVrMo',
  baseUrl: 'https://api.ilmversity.net',
}

export const MAX_FILE_SIZE = {
  name: '10 MB',
  value: 10485760,
}
export const CONCURRENT_FILE_UPLOADS = 5
export const ALLOWED_EXTS = [
  'pdf',
  'doc',
  'docx',
  'txt',
  'rtf',
  'xls',
  'xlsx',
  'mp3',
  'jpeg',
  'jpg',
  'png',
]

export const ALLOWED_EXTS_CLASS = 'pdf, doc, docx, txt, rtf, xls, xlsx, mp3, jpeg, jpg, png'

export const GRADE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const GENERAL = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const ACCESS_TOKEN_KEY = 'accessToken'
export const TENANT_KEY = 'tenant'
export const LOGIN_URL_KEY = 'loginUrl'
export const USER_KEY = 'user'
export const USER_TYPE_KEY = 'userType'
export const SELECTED_CHILD_KEY = 'selectedChild'
export const APP_SETTINGS_KEY = 'appSettings'
export const BACKGROUND_IMAGE = 'backgroundImage'
export const NA = 'N/A'
export const IMAGE = 'image'
export const VIDEO = 'video'
export const WEBVIEW_PARAM = 'mlayout=false'

export const BASE_HOSTS = [
  'ilmversetech.com',
  'ilmversity.com',
  'ilmversity.online',
  'ilmversity.dev',
]

const defaultTenant = process.env.REACT_APP_DEFAULT_TENANT || 'myschool'
const hostname = window.location.hostname.replace('www.', '')
const tenantName = BASE_HOSTS.some((baseHost) => hostname.includes(baseHost))
  ? hostname.split('.')[0]
  : null

export const DEFAULT_TENANT_VALUE =
  process.env.REACT_APP_ENV === 'local' || !tenantName ? defaultTenant : tenantName

export const TENANT_CUSTOM_DOMAINS = {
  aligarh: ['parents.aligarhonline.edu.pk'],
  alsuffah: ['alsuffahacademy.com.pk'],
  ccphs: ['portal.ccphs.edu.pk'],
  eureka: ['eureka-pingu.eurekaglobal.org'],
  gsc: ['portal.shalimarcollege.edu.pk'],
  iconcollege: ['icc.ilmversity.net', 'iconcollege.edu.pk'],
  leadership: ['slims.leadershipschool.pk'],
  lgs: ['1view.lahoregrammar.school'],
  lyceum: ['portal.lyceumschool.edu.pk'],
  mphs: ['mohsinpublichighschool.edu.pk'],
  psi: ['portal.psi.edu.pk'],
  pss: ['portal.pss.edu.pk'],
  risc: ['miles.rootsinternational.edu.pk'],
  rayden: ['portal.thetitancollege.com'],
  sis: ['myspace.scarsdale.edu.pk'],
  smcs: ['portal.st-michaels.edu.pk'],
  smhss: ['sindhmodelhighschool.edu.pk'],
  theoxford: ['oss.edu.pk'],
  twpsc: ['lms.twpsc.edu.pk'],
  whales: ['portal.whalescollege.com'],
  wlgs: ['portal.worldlearninginternational.org'],
  zicas: ['zicasschool.com'],
}

export const TENANT_CUSTOM_IMAGES: {
  [key: string]: {background_image?: string; big_logo?: string; small_logo?: string}
} = {
  lgs: {
    background_image: toAbsoluteUrl('/media/background/lgs_auth_bg_blue.png'),
    big_logo: toAbsoluteUrl('/media/logos/lgs_logo_big_blue.png'),
    small_logo: toAbsoluteUrl('/media/logos/lgs_logo_small_blue.png'),
  },
  risc: {
    background_image:
      'https://ilm-root-uploads.s3.eu-central-1.amazonaws.com/upload/domain/risc_ilmversity_net/baground/1660111418186_1.jpeg',
    big_logo:
      'https://ilmversity-uploads-bkp.s3.eu-central-1.amazonaws.com/upload/domain/risc.ilmversity.net/logo/big/1722939582590_289298.png',
    small_logo:
      'https://ilmversity-uploads-bkp.s3.eu-central-1.amazonaws.com/upload/domain/risc.ilmversity.net/logo/small/1722939514212_289298.png',
  },
}

export const TENANT_CUSTOM_IFRAME_URL: {[key: string]: string} = {
  lgs: '1view2.lahoregrammar.school',
  risc: 'miles2.rootsinternational.edu.pk',
  rayden: 'portal2.thetitancollege.com',
}

export const DEVICE_LIMIT_FULL_MESSAGE =
  'You are already logged in from another device. Now in order to continue with this device we are going to log you out from other device. Thanks.'

export const VENDOR_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  BLOCKED: 2,
}

export const DIGITAL_REQUEST_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  COMPLETE: 3,
}

export const DIGITAL_REQUEST_TYPE = {
  CLASS_LEAVE: 0,
  ASSESSMENT_LEAVE: 1,
}

export const DIGITAL_REQUEST_OBJECT_TYPE = {
  SUBJECT_LEAVE: 0,
  EXAM_LEAVE: 1,
  TEST_LEAVE: 2,
  ASSIGNMENT_LEAVE: 3,
  CUSTOM_REQUEST: 4,
}

export const TOTAL_MARKS_TYPES = {
  APPLICABLE: 1,
  NOT_APPLICABLE: 0,
}

export const MODULE_PERMISSIONS = {
  MODULE_USER_MANAGER: 1,
  MODULE_SCHEDULE_MANAGER: 2,
  MODULE_ATTENDANCE_MANAGER: 3,
  MODULE_NEWS_MANAGER: 4,
  MODULE_CLASS_SUBJECT_MANAGER: 5,
  MODULE_HOMEWORK_MANAGER: 6,
  MODULE_TEST_MANAGER: 7,
  MODULE_EXAM_MANAGER: 8,
  MODULE_PERFORMANCE_REPORTS: 9,
  MODULE_EXTRA_CURRICULAR_ACTIVITIES: 10,
  MODULE_NOTIFICATION_MANAGER: 11,
  MODULE_BLOG_MANAGER: 12,
  MODULE_FINANCE_MANAGER: 13,
  MODULE_FEE_MANAGER: 14,
  MODULE_HR_MANAGER: 15,
  MODULE_LEVEL_MANAGER: 16,
  MODULE_INVENTORY_MANAGER: 17,
  FEE_COLLECTION_SUMMARY: 18,
  MODULE_QUIZ_SYSTEM: 19,
  MODULE_ATTENDANCE_SCHEDULE: 20,
  MODULE_ROYALTY_MANAGER: 21,
  FEE_SETTINGS: 22,
  SYLLABUS_MANAGER: 23,
  ACCOUNTS_MANAGER: 24,
  DEPARTMENT_MANAGER: 25,
  EXAM_SETTINGS_MANAGER: 26,
  WEBSITE_SETTINGS: 27,
  MODULE_STUDENT_ATTENDANCE: 28,
  MODULE_TEACHER_ATTENDANCE: 29,
  MODULE_ATTENDANCE_SETTINGS: 30,
  MODULE_TEST_TYPES: 31,
  ADD_EDIT_DISCOUNT: 32,
  HR_SETTINGS: 33,
  GENERATE_VOUCHERS: 34,
  FEE_PAYMENT: 35,
  CLEAR_PAYMENT: 36,
  PRINT_VOUCHERS: 37,
  SEND_VOUCHER_NOTIFICATION: 38,
  SEND_VOUCHER_SMS: 39,
  FEE_VOUCHER_EDIT: 40,
  FEE_VOUCHER_UPDATE_PARTICULAR: 41,
  FEE_VOUCHER_ADD_MISC_AMOUNT: 42,
  FEE_VOUCHER_ADD_ADVANCE_FEE: 43,
  FEE_VOUCHER_ADD_TAX: 44,
  FEE_VOUCHER_UPDATE_PARTICULAR_AMOUNT: 45,
  MODULE_VIDEO_LEC_MANAGER: 46,
  MODULE_TAG_MANAGER: 47,
  RESTRICT_STAFF_VIEW_TEACHER_DOCS: 48,
  RESTRICT_STAFF_VIEW_STAFF_DOCS: 49,
  FEE_SCHEDULE: 50,
  MODULE_VENDOR_MANAGER: 51,
  CAIE_RECHECKING: 52,
  MODULE_LIBRARY_MANAGER: 53,
  ADMISSION_FORM: 54,
  MODULE_REQUEST_MANAGER: 55,
  DATA_UPLOAD_MANAGER: 56,
  REPORTCARD_NOTIFICATION: 57,
  DOWNLOAD_ARCHIVE: 58,
  MODULE_ATTENDANCE_AMENDMENT_REQUEST: 59,
  MODULE_ATTENDANCE_AMENDMENT_REQUEST_APPROVE_REJECT: 60,
  ATTENDANCE_OFFICER: 61,
  REGISTRAR: 62,
  MODULE_DIGITAL_REQUEST_MANAGER: 63,
  RESOURCE_CENTER: 64,
  ACTIVITY_MANAGER: 65,
  RESET_PASSWORD: 66,
  ALLOCATE_TEACHERS: 67,
}

export const PERMISSIONS = {
  PERMISSION_CREATE: 1,
  PERMISSION_UPDATE: 2,
  PERMISSION_DELETE: 3,
}

export const TABLES = {
  GENERAL_SETTINGS: 'general_settings',
  ID_CARD_SETTINGS: 'id_card_setting',
  STAFF_ID_CARD_SETTINGS: 'staff_id_card_settings',
  OUTLINE_SETTINGS: 'outline_settings',
  COMPREHANSIVE_SETTINGS: 'comprehansive_report_settings',
  PREDICTED_SETTINGS: 'predicted_grades_report_setting',
  EAR_SERVICES: 'ear_services',
}

export const FILE_TYPE = {
  CLASS_SUBJECT_TEACHER: 'class_subject_teacher',
  STUDENT_PARENT: 'student_parent',
  TAGS: 'tags',
  LABELS: 'labels',
  INVENTORY: 'inventory',
  STUDENT_HOMEWORK: 'student_homework',
  STUDENT_EXAM: 'student_exam',
  STUDENT_TEST: 'student_test',
  ANNUAL_CALENDAR: 'annual_calendar',
}

export const GENDER = {
  MALE: 0,
  FEMALE: 1,
}

export const ONBOARD_PROGRESS = {
  STEP_1: 33,
  STEP_2: 66,
  STEP_3: 99,
  LAST_STEP: 100,
}
export const ATTENDANCE_SETTING_TABLES = {
  ATTENDANCE_SETTING: 'attendance_setting',
}

export const CUSTOM_SELECT_ALL_VALUE = 'option_select_all_filtered'

export const SEND_ATTENDANCE_SMS_FOR = [
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.NONE', value: 0},
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.ONLY_ABSENT', value: 1},
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.ABSENT_PRESENT', value: 2},
  {
    label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.ABSENT_PRESENT_LATE_PRESENT',
    value: 3,
  },
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.ONLY_PRESENT', value: 4},
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.PRESENT_LATE_PRESENT', value: 5},
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.ONLY_LATE_PRESENT', value: 6},
  {label: 'ATTENDANCE_SETTING_BASIC_SETTINGS.ATTENDANCE_STATUS.SMS.LATE_PRESENT_ABSENT', value: 7},
]

export const SEND_ATTENDANCE_NOTIFICATION_FOR = [
  {label: 'ATTENDANCE_SETTING.BASIC_SETTINGS.NOTIFICATION.NONE', value: 0},
  {label: 'ATTENDANCE_SETTING.BASIC_SETTINGS.NOTIFICATION.ONLY_ABSENT', value: 1},
  {label: 'ATTENDANCE_SETTING.BASIC_SETTINGS.NOTIFICATION.ABSENT_PRESENT_LATE', value: 2},
]

export const SEND_ATTENDANCE_CHECKOUT_NOTIFICATION_SMS_FOR = [
  {label: 'ATTENDANCE_SETTING.CHECKOUT_SETTINGS.NOTIFICATION_SMS.NONE', value: 0},
  {label: 'ATTENDANCE_SETTING.CHECKOUT_SETTINGS.NOTIFICATION_SMS.EARLY_CHECKOUT', value: 1},
  {
    label: 'ATTENDANCE_SETTING.CHECKOUT_SETTINGS.NOTIFICATION_SMS.EARLY_CHECKOUT_ON_TIME_CHECKOUT',
    value: 2,
  },
]

export const WEEKEND_DAYS = [
  {label: 'ATTENDANCE_SETTING.BIOMETRIC_SETTINGS.WEEKEND_DAYS.FRIDAY', value: 'friday'},
  {label: 'ATTENDANCE_SETTING.BIOMETRIC_SETTINGS.WEEKEND_DAYS.SATURDAY', value: 'saturday'},
  {label: 'ATTENDANCE_SETTING.BIOMETRIC_SETTINGS.WEEKEND_DAYS.SUNDAY', value: 'sunday'},
]

export const TEACHER_STAFF_ATTENDANCE_TIME = [
  {label: 'ATTENDANCE_SETTING_BIOMETRIC_SETTINGS.FROM_SETTING', value: 0},
  {label: 'ATTENDANCE_SETTING_BIOMETRIC_SETTINGS.FROM_SCHEDULE', value: 1},
  {label: 'ATTENDANCE_SETTING_BIOMETRIC_SETTINGS.FROM_TIME_TABLE', value: 2},
]

export const STUDENT_ATTENDANCE_TIME = [
  {label: 'ATTENDANCE_SETTING_BIOMETRIC_SETTINGS.FROM_SETTING', value: 0},
  {label: 'ATTENDANCE_SETTING_BIOMETRIC_SETTINGS.FROM_SCHEDULE', value: 1},
]

export const ABSENT_COUNT = [
  {label: '', value: 0},
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
]

export const FIRST_CHECKOUT_LAST_CHECKOUT = [
  {label: 'ATTENDANCE_SETTING.BIOMETRIC_SETTINGS.FIRST_CHECKOUT', value: 0},
  {label: 'ATTENDANCE_SETTING.BIOMETRIC_SETTINGS.LAST_CHECKOUT', value: 1},
]

export const ATTENDANCE_REMARKS_TYPES = [
  {label: 'USER.STDHEADING', value: 1},
  {label: 'MENU.TEACHERS', value: 2},
]

export const ATTENDANCE_STATUS = {
  STATUS_ABSENT: 0,
  STATUS_PRESENT: 1,
  STATUS_LEAVE: 2,
  STATUS_LATE_PRESENT: 3,
  STATUS_ON_TIME_CHECKOUT: 4,
  STATUS_EARLY_CHECKOUT: 5,
  STATUS_BUNK: 6,
  STATUS_UNMARK: 7,
}

export const ATTENDANCE_SPECIAL_STATUS = {
  SPECIAL_STATUS_NORMAL: 0,
  SPECIAL_STATUS_LATE_COUNT_ABSENT: 1,
  SPECIAL_STATUS_BUNK_ABSENT: 2,
  SPECIAL_STATUS_MARGIN_ABSENT: 3,
}

export const ATTENDANCE_TEACHER_STAFF_TYPES = [
  {label: 'MENU.TEACHERS', value: 2},
  {label: 'MENU.STAFF', value: 5},
]

export const ATTENDANCE_GRID_TYPES = {
  STUDENT: 1,
  TEACHER_STAFF: 2,
  STUDENT_SUBJECTWISE: 3,
  TEAHCER_STAFF_SUBJECTWISE: 4,
}

export const CLASSES_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
}

export const CLASSES_SUBJECT_STATUS = {
  NO: 0,
  YES: 1,
}

export const SUBJECTS_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
}

export const SUBJECTS_PRACTICAL_STATUS = {
  NO: 0,
  YES: 1,
}

export const SUBJECTS_GRADE_SUBJECT_STATUS = {
  NO: 0,
  YES: 1,
}

export const SUBJECTS_MINOR_SUBJECT_STATUS = {
  NO: 0,
  YES: 1,
}

export const GENERAL_SETTINGS_SUBJECTWISE_PREFERENCES = {
  SUBJECTWISE_PREFERENCE_DISBALED: 0,
  SUBJECTWISE_PREFERENCE_ENABLED: 1,
}

export const ATTENDANCE_SETTING_ATTENDANCE_EDIT_OPTIONS = {
  DISBALED: 0,
  ENABLED: 1,
}

export const ATTENDANCE_SETTING_TEACHER_STAFF_LATE_ATTENDANCE_EDIT_OPTIONS = {
  DISBALED: 0,
  ENABLED: 1,
}

export const ATTENDANCE_SETTING_STUDENT_LATE_ATTENDANCE_EDIT_OPTIONS = {
  DISBALED: 0,
  ENABLED: 1,
}

export const ATTENDANCE_SETTING_TEACHER_SUBJECTWISE_LATE_ATTENDANCE_EDIT_OPTIONS = {
  DISBALED: 0,
  ENABLED: 1,
}

export const ATTENDANCE_SETTING_STUDENT_SUBJECTWISE_LATE_ATTENDANCE_EDIT_OPTIONS = {
  DISBALED: 0,
  ENABLED: 1,
}

export const GENERAL_ENABLE_DISABLE = {
  DISABLE: 0,
  ENABLE: 1,
}

export const ATTENDANCE_BIOMETRIC_STATUS = {
  MANUAL: 0,
  NODE_API: 1,
  ZOOM: 2,
  GOOGLE_MEET: 3,
  CAST: 4,
  MIGRATION: 5,
  CSV: 6,
  ATTENDANCE_AMENDMENT_REQUEST: 7,
  DIGITAL_LEAVE: 8,
}

export const ATTENDANCE_BIOMETRIC_STATUS_LABEL = [
  'ATTENDANCE.MARKED_THROUGH.MANUAL',
  'ATTENDANCE.MARKED_THROUGH.NODE_API',
  'ATTENDANCE.MARKED_THROUGH.ZOOM',
  'ATTENDANCE.MARKED_THROUGH.GOOGLE_MEET',
  'ATTENDANCE.MARKED_THROUGH.CAST',
  'ATTENDANCE.MARKED_THROUGH.MIGRATION',
  'ATTENDANCE.MARKED_THROUGH.CSV',
  'ATTENDANCE.MARKED_THROUGH.ATTENDANCE_AMENDMENT_REQUEST',
  'ATTENDANCE.MARKED_THROUGH.MANUAL_LEAVE',
]

// export const ATTENDANCE_STATUS_LABEL = [
//   {label: 'ATTENDANCE.ATTENDANCE_GRID.STATUS_ABSENT', value: 0},
//   {label: 'ATTENDANCE.ATTENDANCE_GRID.STATUS_PRESENT', value: 1},
//   {label: 'ATTENDANCE.ATTENDANCE_GRID.STATUS_LEAVE', value: 2},
//   {
//     label: attendance_setting?.late_attendance_text
//       ? attendance_setting?.late_attendance_text
//       : 'ATTENDANCE.ATTENDANCE_GRID.STATUS_LATE',
//     value: 3,
//   },
// ]

export const ATTENDANCE_STATUS_LEAVE = [
  {label: 'ATTENDANCE.ATTENDANCE_GRID.STATUS_LEAVE', value: 2},
]

export const ATTENDANCE_CHECKOUT_STATUS_LABEL = [
  {label: 'ATTENDANCE.ATTENDANCE_GRID.CHECKOUT_ON_TIME_CHECKOUT', value: 4},
  {label: 'ATTENDANCE.ATTENDANCE_GRID.CHECKOUT_EARLY_CHECKOUT', value: 5},
]

export const STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT_COLUMNS_LABEL = [
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.PRESENT', value: 1},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.ABSENT', value: 0},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.LEAVE', value: 2},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.LATE', value: 3},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.BUNKS', value: 6},
]

export const TEACHER_STAFF_COMBINE_ATTENDANCE_REPORT_COLUMNS_LABEL = [
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.PRESENT', value: 1},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.ABSENT', value: 0},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.LEAVE', value: 2},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.LATE', value: 3},
  {label: 'STUDENT_SUBJECT_COMBINE_ATTENDANCE_REPORT.COLUMNS.EARLY_CHECKOUT', value: 5},
]

export const PARENT_CREATION_TYPE = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
}

export const CLASS_WISE_INSTANCE = 0
export const SUBJECT_WISE_INSTANCE = 1
export const GRADE_WISE_INSTANCE = 1

export const INACTIVE_REASON_INACTIVE = 0
export const INACTIVE_REASON_NEW_ADMISSION = 1
export const INACTIVE_REASON_ALUMNI = 2
export const INACTIVE_REASON_OTHERS = 3

export const BUILD_VERSION = '1.220.100 '

export const IMAGE_SUPPORTED_EXT = 'image/png, image/jpeg, image/jpg'

export const DOC_EXTS_SUPPORTED = '.pdf, .doc, .docx, .txt, .rtf, .xls, .xlsx, .csv'
export const SIGNATURE_IMAGE_SUPPORTED_EXT = 'image/jpeg, image/jpg'

export const HOMEWORK_RESULTS = {
  PARTIAL: 2,
  COMPLETE: 1,
  NONE: 0,
}

export const REGISTRATION_STATUS = {
  REGISTERED_AND_ADMITTED: 0,
  ONLY_REGISTERED: 1,
}

export const MARITAL_STATUS = {
  MARRIED: 0,
  SEPARATED: 1,
  DIVORCED: 2,
  WIDOW: 3,
}

export const TEACHER_MARITAL_STATUS = {
  SINGLE: 0,
  MARRIED: 1,
  SEPARATED: 2,
  DIVORCED: 3,
  WIDOW: 4,
}

export const SHOW_ILMVERSITY_LOGO = false

export const DEVICE_TYPES = {
  ANDROID: 1,
  IOS: 2,
  HYBRID: 3,
  HYBRID_ANDROID: 4,
  ANDROID_NEW: 5,
  IOS_NEW: 6,
  WEB: 7,
}

export const ADMISSION_STAGES = {
  INQUIRY_STAGE: 0,
  INTERVIEW_SCHEDULED: 1,
  INTERVIEW_DONE: 2,
  ADMISSION_OFFERED: 3,
  ADMISSION_APPROVED: 4,
  ADMISSION_NOT_APPROVED: 5,
  OTHER_STAGE: 6,
  STAGE_7: 7,
  STAGE_8: 8,
  STAGE_9: 9,
  STAGE_10: 10,
}

export const PREVIOUS_GRADING = [
  {id: 1, is_default: 1, A: 74, B: 65, C: 55},
  {id: 2, is_default: 0, A: 69, B: 60, C: 50},
  {id: 3, is_default: 0, A: 59, B: 50, C: 40},
  {id: 4, is_default: 0, A: 49, B: 40, C: null},
]

export const ACAD_HISTORY_RECORD_TYPE = {
  CIE_GRADES: 1,
  EXAM_MARKS: 2,
}

export const ACAD_HISTORY_RECORD_LIST = [
  {value: ACAD_HISTORY_RECORD_TYPE.CIE_GRADES, label: 'CIE Grades'},
  {value: ACAD_HISTORY_RECORD_TYPE.EXAM_MARKS, label: 'Exam Marks'},
]

export const SUPPORT_EMAIL = 'support@ilmversity.com'

export default constants
