import {
  GET_USER_SUBJECTS,
  GET_DEPARTMENT,
  GET_BRANCH,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_GENERAL_SETTINGS,
  UPDATE_GENERAL_SETTINGS,
  GET_ID_CARD_SETTINGS,
  GET_STAFF_ID_CARD_SETTINGS,
  GET_OUTLINE_SETTINGS,
  GET_COMPREHANSIVE_SETTINGS,
  GET_PREDICTED_SETTINGS,
  GET_ADMISSION_SETTINGS,
  GET_ADMISSION_SECTIONS_SETTINGS,
  UPDATE_ENABLE_ADMISSION_FORM_SETTING,
  GET_DEPARTMENTS,
} from '../../Actions/General/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_USER_SUBJECTS, fetchData)

  yield takeEvery(GET_DEPARTMENTS, fetchData)
  yield takeEvery(GET_DEPARTMENT, fetchData)
  yield takeEvery(CREATE_DEPARTMENT, fetchData)
  yield takeEvery(UPDATE_DEPARTMENT, fetchData)
  yield takeEvery(DELETE_DEPARTMENT, fetchData)

  yield takeEvery(GET_BRANCH, fetchData)

  yield takeEvery(GET_GENERAL_SETTINGS, fetchData)
  yield takeEvery(UPDATE_GENERAL_SETTINGS, fetchData)

  yield takeEvery(GET_ID_CARD_SETTINGS, fetchData)
  yield takeEvery(GET_STAFF_ID_CARD_SETTINGS, fetchData)
  yield takeEvery(GET_OUTLINE_SETTINGS, fetchData)
  yield takeEvery(GET_COMPREHANSIVE_SETTINGS, fetchData)
  yield takeEvery(GET_PREDICTED_SETTINGS, fetchData)
  yield takeEvery(GET_ADMISSION_SETTINGS, fetchData)
  yield takeEvery(GET_ADMISSION_SECTIONS_SETTINGS, fetchData)
  yield takeEvery(UPDATE_ENABLE_ADMISSION_FORM_SETTING, fetchData)
}

export default dataSaga
